import React, { useRef, useState, useEffect } from "react";
import Avatar from "../icons/Avatar"
import UserIcon from "../icons/User"
import PinIcon from "../icons/Pin"
import ChatsIcon from "../icons/Chats"
import { Utils } from "../../utils"
import DOMPurify from "dompurify";
import LikeIcon from "../icons/Like";
import UnLikeIcon from "../icons/UnLike";
import TruckIcon from "../icons/Truck"
import { likePost, likeReplyComment } from "../../core/communities";
import ReplyIcon from "../icons/Reply";
import ForumAvatar from "../forum/ForumAvatar";
import DeleteIcon from "../icons/Delete"
import EditIcon from "../icons/Edit"
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import LoginAlertModal  from "../auth/LoginAlertModal"

// https://vhudyma-blog.eu/render-raw-html-in-react/
const PostHeroCard = ({ isComment = false, post, forum, onUserLikedPost, onOpenCommentFormAfter, index = -1, userRefs = {}, onUserLikedComment, onOpenEditCommentFormAfter, onOpenDeleteComment, onOpenDeletePost, isProfileView = false, hasTinyMCE = false }) => {
    const navigate = useNavigate();
    let details = isComment ? post.details : post.description;
    const decodedDetails = atob(details);
    const decodedHtml = DOMPurify.sanitize(decodedDetails, {
        USE_PROFILES: { html: true },
    });
    const cleanHtml = decodeURIComponent(decodedHtml);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }
    const checkOwner = post.user._id == loggedInUser?._id;
    const [isOwner, setIsOwner] = useState(checkOwner);

    //if if I already like this
    let iLikedAlready = false;
    for (let index = 0; loggedInUser && index < post?.likes.length; index++) {
        const like = post.likes[index];
        if (like._id === loggedInUser?._id) {
            iLikedAlready = true;
            break;
        }

    }

    let nameToLink = function (user) {
        let name = user.name;
        if (user._id === loggedInUser?._id) {
            name = "You";
        }
        return `<a className='hover:underline cursor-pointer'>${name}</a>`;
    }
    // let likeComment = "Be the first to like this.";
    // if (post.likes.length === 1) {
    //     const name = nameToLink(post.likes[0]).trim();
    //     const you = "<a className='hover:underline cursor-pointer'>you</a>";
    //     likeComment = `${name} like${(name.toLowerCase() === you.toLowerCase()) ? "d" : "s"} this.`;
    // } else if (post.likes.length === 2) {
    //     likeComment = `${nameToLink(post.likes[0])} and ${nameToLink(post.likes[1])} like this.`;
    // } else if (post.likes.length === 3) {
    //     likeComment = `${nameToLink(post.likes[0])}, ${nameToLink(post.likes[1])} and ${nameToLink(post.likes[2])} like this.`;
    // } else if (post.likes.length > 3) {
    //     let remain = post.likes.length - 3;
    //     let othersWord = `${remain} others`;
    //     let likeWord = "like";
    //     if (remain == 1) {
    //         othersWord = "1 other";
    //         likeWord = "likes";
    //     }
    //     likeComment = `
    //         ${nameToLink(post.likes[0])}, 
    //         ${nameToLink(post.likes[1])}, 
    //         ${nameToLink(post.likes[2])} 
    //         and <a className='hover:underline cursor-pointer mx-1 font-bold'>${othersWord}</a>  ${likeWord} this.`;
    // }
    let repliesCount = 0;
    if (post.replies) {
        repliesCount = post.replies.length;
    }



    let cleanReferencedHtml = "";
    let hasReferencedReply = false;
    let refUserName = "";
    if (post.referenced_reply) {
        hasReferencedReply = true;
        try {
            const refDecodedDetails = atob(post.referenced_reply.details);
            const refDecodedHtml = DOMPurify.sanitize(refDecodedDetails, {
                USE_PROFILES: { html: true },
            });
            cleanReferencedHtml = decodeURIComponent(refDecodedHtml);
            if (Object.hasOwnProperty.call(userRefs, post.referenced_reply.user)) {
                refUserName = userRefs[post.referenced_reply.user].username;
            }
        } catch (error) {
            console.log("this error", post.referenced_reply);
            cleanReferencedHtml = post.referenced_reply.details;
        }
        
    }

    const likePostHelper = async () => {
        const inputs = {
            id: post._id
        };
        let response = await likePost(inputs);
        onUserLikedPost(post, loggedInUser, iLikedAlready);
    }

    const likeCommentHelper = async () => {
        const inputs = {
            id: post._id
        };
        let response = await likeReplyComment(inputs);
        onUserLikedComment(post, loggedInUser, iLikedAlready);
    }

    const handleLike = async (e) => {
        e.preventDefault();
        if (loggedInUserStr) {
            if (isComment) {
                likeCommentHelper();
            } else {
                likePostHelper();
            }
        }else{
            // navigate(`/login`);
            setAlertModalMessage("Login to like");
            toggleLoginAlertModalModal();
        }
    }

    const handleReply = async (e, post) => {
        e.preventDefault();
        if (loggedInUserStr) {
            onOpenCommentFormAfter(e, post);
        }else{
            // navigate(`/login`);
            setAlertModalMessage("Login to reply");
            toggleLoginAlertModalModal();
        }
    }

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleDeleteComment = (e) => {
        e.preventDefault();
        alert("Coming very soon");
    }

    const [alertModalMessage, setAlertModalMessage] = useState("Your need to be logged in");
    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const goToProfile = (e, user) => {
        e.preventDefault();
        navigate(`/profile/${user._id}`);
    };

    return (
        <div className={`flex flex-col max-w-vw w-full bg-white  ${index > 0  && isProfileView === false ? "" : ""} `} id={`#reply-${post._id}`}>
            <LoginAlertModal message={alertModalMessage} isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
            <div className={`flex bg-primary-white ${!isComment?"rounded-md":(index > 0?"":"")}  flex-row  p-3`}>
                <Avatar user={post.user} />
                <div className="flex flex-col ml-2 flex-grow justify-between">
                    <div className="flex flex-row items-center w-full ">
                        <a className="flex flex-row  items-center justify-between  w-full  hover:underline cursor-pointer">
                            <span onClick={(e)=>goToProfile(e,post.user)}  className="font-bold text-black ml-1 text-md leading-[0.8]">
                                {post.user.username}
                            </span>
                            {index != -1 &&
                                <span className="text-xs font-sans text-black ml-1 text-md text-gray-600 leading-none">
                                    #{index + 1}
                                </span>
                            }
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <PinIcon className={'text-purple-900'} />
                        <span className="text-xs ml-1">
                            {post.user.location.city}, {post.user.location.state}
                        </span>
                    </div>
                    <div className="hidden flex flex-row items-center">
                        {/* <a className="flex flex-row  hover:underline cursor-pointer">
                            <ChatsIcon fillColor={'#581c88'} strokeColor={'#ffffff'} className={'w-4'} />
                            <span className="text-xs ml-1 ">
                                {Utils.comma(post.user.count_of_replies)}
                            </span>
                        </a> */}
                        {/* <UserIcon className={'text-purple-900'} /> */}
                        {/* <span className="text-xs ml-1"> member since: </span>
                        <span className="text-xs ml-2">
                            {Utils.formatDate(post.user.join_date.split("T")[0], "month date, year")}
                        </span> */}
                    </div>
                    <div className="text-left flex flex-row text-gray-500 text-xs font-sans ">
                        <ChatsIcon fillColor={'#581c88'} strokeColor={'#ffffff'} className={'w-4 mr-1 self-start'} />
                        {isComment &&
                            <div className="mr-1">
                                {post.user.username} 
                                {hasReferencedReply === true ? ' replied ' : ' commented '}
                                
                            </div>
                        }
                        {!isComment &&
                            <div className="mr-1">Posted </div>
                        }
                        <div className="flex whitespace-normal flex-wrap">
                            {/* {` ${Utils.formatDate(post.createdAt, "month date, year")}`} */}
                            <ReactTimeAgo date={post.createdAt} locale="en-US" />
                        </div>
                    </div>
                </div>
            </div>

            
            {((post?.media??"").length > 0) &&
                <div className="block min-w-[100%] w-[100%] cursor-pointer bg-black" >
                    <div className="min-w-[100%] w-[100%] h-[280px] md:h-[480px] bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${post.media})` }} >
                    </div>
                </div>
            }

            {forum &&
                <div className="flex flex-row bg-primary-white px-3 border-b border-gray-100" >
                    {/* <div className="h-14 w-14 min-w-14 bg-purple-800x flex justify-center items-center text-purple-300 font-bold rounded-sm">
                        <ForumAvatar forum={forum} />
                    </div> */}
                    <div className="flex flex-col flex-1 ">
                        {/* <div className="text-left text-gray-500 text-sm font-sans">
                            Discussion in <a className="underline underline-offset-4 mx-1">{forum?.title}</a>
                            started by <a className="underline underline-offset-4 ">{post.user.username}</a>
                            <span className="mr-3">,</span> on <i className="underline underline-offset-4">{Utils.formatDate(post.createdAt, "month date, year")}</i>
                        </div> */}
                        {/* <div className="text-left text-gray-800 text-lg font-bold">
                            {post.title}
                        </div> */}
                        {/* {post.sub_title && post.sub_title.length > 0 &&
                            <div className="text-left text-gray-600 text-md font-normal">
                                {post.sub_title}
                            </div>
                        } */}

                    </div>
                </div>
            }


            <div className={`flex flex-col bg-white border-t-gray-200 border-t ${!hasTinyMCE?"rounded-b-md":""}  p-3`} >
                {/* <div className="text-left text-gray-800 text-lg font-bold py-2">
                    {post.title}
                </div> */}
                {!isComment &&
                    <div className="mb-2 text-sm font-sans text-gray-400 flex flex-row justify-between items-center  border-b border-gray-100">
                        <span className="text-left text-gray-800 text-lg font-bold pb-2" >
                            {/* {Utils.formatDate(post.createdAt, "month date, year")} */}
                            {post.title} 
                        </span>
                        <span>
                            {/* #{post._id} */}
                        </span>
                    </div>
                }
                <div>
                    <div className="flex flex-col " >
                        <article className="overflow-scrollx">
                            {hasReferencedReply === false &&
                                <blockquote
                                    className="font-sans messageText SelectQuoteContainer ugc baseHtml fs-14px text-[#383938]"
                                    dangerouslySetInnerHTML={{ __html: cleanHtml }}>
                                </blockquote>
                            }
                            {hasReferencedReply === true &&
                                <blockquote
                                    className="font-sans messageText SelectQuoteContainer ugc baseHtml fs-14px text-[#383938]">
                                    {!post.referenced_reply.is_marked_for_deleting &&
                                        <div className="bbCodeBlock bbCodeQuote" data-author="msdesignltd">
                                            <aside>
                                                <div className="attribution type">
                                                    Replying to 
                                                    <a className="ml-1 hover:underline cursor-pointer mr-2"><b>{refUserName}</b></a>
                                                    
                                                    <span onClick={(e) => handleClickScroll(`#reply-${post.referenced_reply._id}`)} className="AttributionLink">↑</span>
                                                </div>
                                                <blockquote className="quoteContainer">
                                                    <div className="quote"   dangerouslySetInnerHTML={{ __html: cleanReferencedHtml }} ></div>
                                                </blockquote>
                                            </aside>
                                        </div>
                                    }
                                    {post.referenced_reply.is_marked_for_deleting &&
                                        <div className="bbCodeBlock bbCodeQuote" data-author="msdesignltd"  >
                                            Quoted comment has been removed.
                                        </div>
                                    }
                                    <div style={{marginLeft: "10px"}} dangerouslySetInnerHTML={{ __html: cleanHtml }}></div>
                                </blockquote>
                            }
                        </article>
                    </div>
                    {/* {!isComment &&
                        <div className="flex flex-col w-full py-5"  >
                            <img src={post.media} className="w-full" />
                        </div>
                    } */}
                    {isProfileView === false && 
                        <div className="rounded-b-md flex flex-row items-center py-2 mt-5 px-2 text-sm font-sans items-center justify-between border-t border-gray-100">
                            <div className="flex flex-row items-center text-gray-500 text-sm pt-3">
                                <LikeIcon outline={true} className={post.likes.length > 0 ? 'text-purple-900 ' : 'w-[13px]'} />
                                <div className={`ml-1 mr-1 text-xs ${post.likes.length > 0 ? "text-purple-900" : ""}`} dangerouslySetInnerHTML={{ __html: post.likes.length }} ></div>
                                <ChatsIcon fillColor={repliesCount > 0 ? '#581c87' : '#6b7280'} strokeColor={'#ffffff'} className={'w-4 '} />
                                <div className={`ml-1 text-xs ${repliesCount > 0 ? 'text-purple-900' : ''}`} dangerouslySetInnerHTML={{ __html: repliesCount }} ></div>
                            </div>
                            <div className="whitespace-nowrap flex flex-row items-center">
                                <div className="flex items-center gap-x-2">
                                    {isComment &&
                                        <button role="button" 
                                            onClick={(e) => handleReply(e, post)}
                                            aria-label="reply to post"
                                            className="flex items-center gap-x-2 pt-1 rounded-sm text-gray-500 hover:text-purple-900  text-sm font-semibold "
                                        >
                                            <ReplyIcon className={'text-sm'} />
                                            Reply
                                        </button>
                                    }


                                    <button role="button" 
                                        onClick={handleLike}
                                        aria-label="like button"
                                        className={`flex items-center gap-x-2 pt-2 rounded-sm  ${!iLikedAlready ? 'text-gray-500' : 'text-purple-900 cursor-pointer '}   px-3 py-1 text-sm font-semibold  `}
                                    >
                                        <LikeIcon />
                                        Like
                                    </button>



                                    {((isOwner || loggedInUser?.role === "admin") && isComment === false) &&
                                        <a
                                            href={`/community/${forum.community._id}/forum/${forum._id}/post/${post._id}/edit`}
                                            className="rounded-sm text-gray-600  text-sm font-semibold  hover:text-purple-800 "
                                        >
                                            {/* This is editing a post */}
                                            <EditIcon />
                                        </a>
                                    }
                                    {((isOwner || loggedInUser?.role === "admin") && isComment === true) &&
                                        <button role="button" 
                                            aria-label="edit comment or edit reply button"
                                            onClick={(e) => onOpenEditCommentFormAfter(e, post)}
                                            className="rounded-sm text-gray-600 text-sm font-semibold  hover:text-purple-800 "
                                        >
                                            {/* This is for editing a comment/reply */}
                                            <EditIcon  />
                                        </button>
                                    }
                                    {((loggedInUser?.role === "admin") && isComment === false) &&
                                        // Deletes a post 
                                        <button role="button" 
                                            aria-label="delete post"
                                            onClick={(e) => onOpenDeletePost(e, post)}
                                            className="rounded-sm text-gray-600 text-sm font-semibold hover:text-red-600 "
                                        >
                                            <DeleteIcon />
                                        </button>
                                    }
                                    {((loggedInUser?.role === "admin") && isComment) &&
                                        // Deletes a comment/Reply 
                                        <button role="button" 
                                            aria-label="delete a comment or delete a reply button"
                                            onClick={(e) => onOpenDeleteComment(e, post)}
                                            className="rounded-sm text-gray-600 text-sm font-semibold hover:text-red-600 "
                                        >
                                            <DeleteIcon />
                                        </button>
                                    }
                                </div>

                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
}

export default PostHeroCard;
