import React, { useState, useEffect, useReducer } from "react";
import { fetchFeed } from "../../utils/http";
import { Utils } from "../../utils";

const reducer = (cursor, action) => {
    switch (action.type) {
        case "Increment":
            let new_point = cursor + 1;
            if (new_point > action.max) {
                new_point = 0;
            }
            return new_point;
        default:
            return cursor;
    }
}

const RssFeeds = ({ source, onFeedsLoaded }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [rssData, setRssData] = useState([]);
    const [renderedFeeds, setRenderedFeeds] = useState([]);
    const [cursor, dispatch] = useReducer(reducer, 0);
    const [loadOnce, setLoadOnce] = useState(1);






    if (typeof onFeedsLoaded == 'undefined') {
        onFeedsLoaded = (x) => x;
    }

    const fetchRssFeeds = async () => {
        setIsLoading(true);
        // const rssFeeds = await fetchFeed(source.url, false);
        // console.log("rssFeeds ", rssFeeds);
        // let list = source.get(rssFeeds);
        let list = source.get();
        console.log("near return list ", list);
        setIsLoading(false);
        Utils.shuffle(list);
        // console.log("after shuffle ", list);
        setRssData(list);
        // console.log("after set ", rssData);
        onFeedsLoaded(list.length);
        // if (list.length > 0) {
        //     setRenderedFeeds([list[0]]);
        // }
        // console.log("near return list ", rssData);
        return list;
    }

    useEffect(() => {
        async function fetchData() {
            const list = await fetchRssFeeds();
            const min = 10000
            const max = 25000
            let rand = Math.ceil(Math.random() * max);
            rand = rand < min ? min : rand;
            // temp to disable rss feeds loading continuosly
            // setInterval(() => {
            //     dispatch({ type: "Increment", max: list.length - 1 });
            // }, rand);

            // if (intervalIdRef.current == null) {
            //     intervalIdRef.current = setInterval(() => {
            //         if (list.length > 0) {

            //             console.log("list.length", list.length);
            //             console.log("cursorRef.current", cursorRef.current);
            //             console.log("currentIndex", currentIndex);

            //             setRenderedFeeds([list[cursorRef.current]]);

            //             let new_index = cursorRef.current + 1;
            //             if (new_index >= list.length) {
            //                 new_index = 0;
            //             }
            //             setCurrentIndex(new_index);
            //             cursorRef.current = new_index;
            //         } else {
            //             console.log("in thr else ", list, list.length);
            //         }
            //     }, 5000);
            // }
        }
        fetchData();
    }, []);

    // w-48 xl:w-full  xl:px-1 h-[120px] xl:h-[150px]
    return (
        <>
            {rssData.length > 0 && (
                <div key={cursor + (Math.random() * Math.random())}  
                    className="flex flex-row lg:flex-col gap-x-2 lg:gap-x-0 lg:gap-y-2 lg:px-1 overflow-auto lg:overflow-['unset'] sm:rounded-sm p-1 lg:p-0 mb-1 ">
                    {[rssData[cursor]].map((rssFeed, index) => {
                        let img = rssFeed.thumbnail_url;
                        let style = {
                            backgroundImage: `url('${img}')`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        };
                        return (
                            <div key={`${index}-${cursor}`} className="w-[350px] lg:w-full  h-[107px]   animate-fade lg:h-auto rounded-md border border-grey-900 flex flex-row lg:flex-col  "  >
                                <div className="min-w-[150px] w-[150px] lg:w-full min-h-[107px]">
                                    <a href={rssFeed.link} rel="noreferrer" target="_blank" className="w-full h-full">
                                        <img
                                            alt="entry img"
                                            onError={() => console.warn("error occured")}
                                            src={rssFeed.thumbnail_url}
                                            className="w-full  rounded-t-md hidden lg:block"
                                        />
                                        <div className="w-full h-full rounded-l-md  lg:hidden" style={style} ></div>
                                    </a>
                                    {/* <a href={rssFeed.link} rel="noreferrer" target="_blank">{`[Open]`}</a> */}
                                </div>
                                <div className="min-w-[150px] w-[200px] lg:w-full lg:min-w-full bg-slate-100 rounded-b-md p-1">
                                    <a href={rssFeed.link} rel="noreferrer" target="_blank" className="block flex flex-col">
                                        <p className="text-xs lg:text-md  lg:text-xs font-semibold font-medium min-h-[43px] line-clamp-2 lg:line-clamp-none" style={{ lineHeight: "1.2" }}>{rssFeed.title}</p>
                                        <p className="text-xs font-medium pt-1 overflow-ellipsis">{rssFeed.author}</p>
                                        <p className="text-gray-700 text-xxs font-light">{`Released: ${new Date(rssFeed.publish_date).toDateString()}`}</p>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {rssData.length == 0 && (
                <x-placeholder message="Right column area" >
                    <div className="relative rounded-md h-[150px] overflow-hidden border border-dashed border-gray-400 opacity-75">
                        <svg className="absolute inset-0 h-full w-full stroke-gray-900/10" fill="none">
                            <defs>
                                <pattern id="pattern-1b61a508-0497-4b7f-8a04-44300b5c3e3a" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                                    <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
                                </pattern>
                            </defs>
                            <rect stroke="none" fill="url(#pattern-1b61a508-0497-4b7f-8a04-44300b5c3e3a)" width="100%" height="100%"></rect>
                        </svg>
                    </div>
                </x-placeholder>
            )}
        </>
    );
};

export default RssFeeds;