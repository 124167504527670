import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, loginViaGoogle } from "../core/auth";
import Loader from 'react-loaders';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Eye from "../components/icons/Eye";
import EyeSlash from "../components/icons/EyeSlash";
import { useAuth } from '../utils/AuthProvider';
import { useLocation } from "react-router-dom";
// Feature improvements
// Add displyay and feature to show errors
// Sessions of users at this moment the session is not being persisted. Instead the ID is stored in Local storage to trackusage

export default function Login() {
  const location = useLocation();
  
  // Extract return-to from query params
  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get("return-to") || "/";

  const { logon } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const clearForm = () => {
    setEmail("");
    setPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setIsLoading(true);
    const inputs = { email, password };
    let response = await login(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }else{
      logon();
      clearForm();
      navigate(returnTo);
    }
  };

  const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
  const handleLoginWithGoogle = async (credentialResponse) => {
    if (isLoading) {
      return false;
    }
    setErrors([]);

    setIsLoading(true);
    let inputs = {
      clientId: credentialResponse.clientId,
      jwtToken: credentialResponse.credential,
    }
    let response = await loginViaGoogle(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }else{
      logon();
      navigate(returnTo);
    }
  }

  return (
    <>
      <div className="flex min-h-full h-[100vh] flex-1">
        <div className="flex h-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-primary-white">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex flex-row items-center gap-x-3">
                <img
                  className="h-10 w-auto"
                  src="/logo.png"
                  alt="wefayo"
                />
                <a href={isLoading ? '#' : '/'} className={isLoading ? "cursor-not-allowed" : ""}   ><span className="text-4xl font-sans"><b className="font-sans">wefayo</b></span></a>
              </div>


              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Log in to your account
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Not a member yet?{' '}
                <a href={isLoading ? '#' : '/register'} className={"font-semibold text-purple-900 hover:text-purple-500 " + (isLoading ? "cursor-not-allowed" : "")} >
                  Click here to register today.
                </a>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form action="#" method="POST" className="space-y-3" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                      Email address
                      <span className="text-red-900 mx-1">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        disabled={isLoading}
                        onChange={(e) => setEmail(e.target.value)}
                        className="app-sm-md-h mb-0 block w-full rounded-sm border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                      Password
                      <span className="text-red-900 mx-1">*</span>
                    </label>
                    <div className="mt-2 relative">
                      <input
                        id="password"
                        name="password"
                        type={`${showPassword ? "text" : 'password'}`}
                        autoComplete="current-password"
                        required
                        value={password}
                        disabled={isLoading}
                        onChange={(e) => setPassword(e.target.value)}
                        className="app-sm-md-h block w-full rounded-sm border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                      />

                      <button role="button" 
                        onClick={(e) => setShowPassword(!showPassword)}
                        type="button"
                        aria-label="toggle show or hide password"
                        className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                      >
                        {showPassword ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {/* <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        disabled={isLoading}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mb-0 disabled:bg-gray-100 disabled:cursor-progress"
                      />
                      <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Remember me
                      </label> */}
                    </div>

                    <div className="text-sm leading-6">
                      <a href={isLoading ? '#' : '/forgot-password'} className={"font-semibold text-purple-600 hover:text-purple-500 " + (isLoading ? "cursor-not-allowed" : "")}>
                        Forgot password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button role="button" 
                      type="submit"
                      disabled={isLoading}
                      aria-label="submit login form button"
                      className="app-sm-md-h app-sm-fs flex justify-center items-center flex-row gap-x-2  w-full  rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed"
                    >
                      {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                      Log In
                    </button>

                  </div>
                  {errors.length > 0 &&
                    <div className="app-error text-red-400 font-bold text-xs py-1">
                      {errors[0]}
                    </div>
                  }
                  <div className="text-center text-sm text-gray-500 pt-4">
                    ---- OR ----
                  </div>
                  <div>
                    <div className={"relative"}>
                      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                        <GoogleLogin
                          size="medium"
                          text="signin_with"
                          onSuccess={handleLoginWithGoogle}

                          onError={(e) => {
                            console.log('Login with google failed:', e);
                          }}
                        />
                      </GoogleOAuthProvider>
                      {
                        isLoading && <div className="absolute bg-[#ffffff94] top-0 w-full h-[50px] cursor-not-allowed">&nbsp;</div>
                      }
                    </div>

                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
