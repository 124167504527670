import React, { Fragment, useRef, useState,  } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate, useLocation } from "react-router-dom";
import ArrowLongLeft from "../icons/ArrowRight";

const LoginAlertModal = ({ isOpen, closeFun, message = "Log In to view the job details." }) => {

    const location = useLocation();

    const [open, setOpen] = useState(isOpen);
    const cancelButtonRef = useRef(null);

    

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isOpen} onClose={closeFun}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div onClick={closeFun} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full md:items-center md:justify-center p-1 md:text-center sm:items-center sm:p-0 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 "
                        >
                            <Dialog.Panel className="h-[96vh] md:h-auto relative transform overflow-hidden  px-0 pb-0 pt-14 text-left shadow-xl transition-all w-full md:w-[50vw] md:max-w-md md:mx-56">

                                <div className="bg-white mx-0 rounded-md ">

                                    <div className=" flex flex-row justify-between px-3 rounded-t-md items-center ">
                                        <div className="text-gray-900 text-md font-bold text-center">
                                            
                                        </div>
                                        <div className="flex flex-row items-center">

                                            <div className="ml-4 mr-1 text-gray-900 font-bold cursor-pointer hover:text-purple-200" onClick={() => closeFun(false)}>
                                                X
                                            </div>
                                        </div>

                                    </div>

                                    <div className="max-h-[90%]  overflow-x-auto flex gap-x-2 flex-col md:flex-row justify-start items-center justify-center rounded-b-md bg-white pb-8 md:pb-4">

                                        <div className="py-5 text-sm self-center">
                                            {message}
                                        </div>

                                        <a
                                            href={`/login?return-to=${location.pathname}`}
                                            type="submit"
                                            className="rounded-sm self-center bg-purple-200  text-purple-900  flex gap-x-1 flex-row items-center justify-center text-center  px-3 py-2 text-sm font-bold  hover:text-purple-800 disabled:cursor-not-allowed"
                                        > 
                                            Go To Login <ArrowLongLeft />
                                        </a>

                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default LoginAlertModal;