import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { useParams } from "react-router-dom";
import JobHeader from "../components/joblisting/JobHeader";
import DOMPurify from "dompurify";
import { getJobDetails, trackClickApply } from "../core/job_listing";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import PinIcon from "../components/icons/Pin";
import JobsDetailsSkeleton from "../components/skeletons/JobDetailsSkeleton";
import { isEmail } from "../utils/Validator";
import MainLayout from "../components/layouts/MainLayout";

const JobDetails = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [job, setJob] = useState();
    const [cleanHtml, setCleanHtml] = useState("");
    const [cleanQualificationsHtml, setCleanQualificationsHtml] = useState("");
    const [cleanSkillsHtml, setCleanSkillsHtml] = useState("");
    const [cleanAccomodationsHtml, setCleanAccomodationsHtml] = useState("");
    const [isOwner, setIsOwner] = useState(false);
    const [errors, setErrors] = useState([]);


    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    useEffect(() => {
        const fetchJobDetails = async () => {
            setIsLoading(true);
            const job = await getJobDetails(id);
            setJob(job);


            if (loggedInUserStr) {
                setIsOwner(job.user._id == loggedInUser._id);
            }

            const details = job.job_details.details;
            const decodedDetails = atob(details);
            let html = DOMPurify.sanitize(decodedDetails, {
                USE_PROFILES: { html: true },
            });

            html = decodeURIComponent(html)
            setCleanHtml(html);

            //qualifications
            const qualifications = job.job_details.qualifications;
            const decodedQualifications = atob(qualifications);
            let qualificationsHtml = DOMPurify.sanitize(decodedQualifications, {
                USE_PROFILES: { html: true },
            });
            qualificationsHtml = decodeURIComponent(qualificationsHtml);
            setCleanQualificationsHtml(qualificationsHtml)

            //skills
            const skills = job.job_details.skills;
            const decodedSkills = atob(skills);
            let skillsHtml = DOMPurify.sanitize(decodedSkills, {
                USE_PROFILES: { html: true },
            });
            skillsHtml = decodeURIComponent(skillsHtml);
            setCleanSkillsHtml(skillsHtml)

            //accomodations
            const accomodations = job.job_details.special_accomodations;
            const decodedAccomodations = atob(accomodations);
            let accomodationHtml = DOMPurify.sanitize(decodedAccomodations, {
                USE_PROFILES: { html: true },
            });
            accomodationHtml = decodeURIComponent(accomodationHtml);
            setCleanAccomodationsHtml(accomodationHtml)
            setIsLoading(false);
        }
        fetchJobDetails();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);

        if (!loggedInUser) {
            navigate(`/login`);
            return false;
        }

        const inputs = { jobId: id, userId: loggedInUser._id };
        let response = await trackClickApply(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }

        //check if its an email
        if (isEmail(job.call_to_action_link)) {
            const subject = encodeURIComponent(`Applying for ${job.job_id} ${job.title}`);
            const href = `mailto:${job.call_to_action_link}?subject=${subject}`;
            const link = document.createElement('a');
            link.target = '_blank';
            link.rel = 'mail';
            link.href = href;
            link.click();
        } else {
            window.open(job.call_to_action_link);
        }
    }

    return (
        <MainLayout currentPage={"jobs"} >
            {isLoading &&
                <JobsDetailsSkeleton />
            }
            { (!isLoading && job) &&
                <div className="flex-grow flex flex-col  ">

                    {isOwner &&
                        <div className="flex bg-primary-white p-1 rounded-sm border-b border-gray-200 items-center justify-between">
                            <div className="flex-grow flex gap-x-2 ml-5">
                                <div className="text-gray-800 text-sm">
                                    <span className="text-purple-900">{job.opens_count}</span> Opened
                                </div>
                                <div className="text-gray-800 text-sm ">
                                    <span className="text-purple-900 ">{job.applied_count}</span> Applied
                                </div>
                            </div>
                            <div>
                                <a href={`/jobs/edit/${id}`} className="w-[100%] text-md inline-flex whitespace-nowrap items-center justify-center rounded-sm bg-purple-900 hover:bg-purple-800 py-1 px-3 text-sm font-semibold leading-6 text-white active:text-white/80 w-full btn-gradient-main" >
                                    Edit Job Details
                                </a>
                            </div>
                        </div>
                    }

                    <JobHeader job={job} isOwner={isOwner} />

                    <div className="bg-white rounded-b-md mb-4  lg:col-span-8 lg:mb-0">
                        <div className="p-3 sm:p-4 md:p-6">
                            <div className="mb-2 ">

                                <div className="text-gray-600 text-sm md:text-base flex flex-col gap-y-3" >
                                    <div className="text-gray-600 font-bold">Job ID</div>
                                    <div className="parsed-html fs-14px" style={{ fontSize: "14px" }} > {job?.job_id}</div>

                                    {cleanHtml.trim().length > 0 &&
                                        <>
                                            <div className="text-gray-600 font-bold">Job Description</div>
                                            <div className="parsed-html fs-14px" style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: cleanHtml }}></div>
                                        </>
                                    }
                                    {cleanQualificationsHtml.trim().length > 0 &&
                                        <>
                                            <div className="text-gray-600 font-bold">Qualifications</div>
                                            <div className="parsed-qualifications-html fs-14px jb-listing" dangerouslySetInnerHTML={{ __html: cleanQualificationsHtml }}></div>
                                        </>
                                    }
                                    {cleanSkillsHtml.trim().length > 0 &&
                                        <>
                                            <div className="text-gray-600 font-bold">Skills</div>
                                            <div className="parsed-skills-html fs-14px jb-listing" dangerouslySetInnerHTML={{ __html: cleanSkillsHtml }}></div>
                                        </>
                                    }
                                    {cleanAccomodationsHtml.trim().length > 0 &&
                                        <>
                                            <div className="text-gray-600 font-bold">Special Accomodations</div>
                                            <div className="parsed-accomodations-html fs-14px jb-listing" dangerouslySetInnerHTML={{ __html: cleanAccomodationsHtml }}></div>
                                        </>
                                    }
                                </div>


                                <div className="mt-10 flex items-center ">
                                    {/* ${isOwner ? 'bg-purple-400 hover:bg-purple-300 cursor-not-allowed' : ''}  */}
                                    <form action="#" method="POST" className="space-y-3" onSubmit={handleSubmit}>
                                        <button role="button" type="submit" disabled={job?.display_status != 'active'} className={`w-[100%] inline-flex whitespace-nowrap items-center justify-center rounded-md bg-purple-900  disabled:bg-purple-400 disabled:cursor-not-allowed disabled:cursor-events-none hover:bg-purple-800 py-2 px-3 text-sm font-semibold leading-6 text-white active:text-white/80 w-full btn-gradient-main`} >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            <div className="text-base font-extrabold">Apply Now</div>
                                        </button>
                                    </form>

                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-1 ml-10">
                                            {errors[0]}
                                        </div>
                                    }

                                </div>

                                <div className="mb-3 mt-5 p-3 text-xs text-purple-900 bg-purple-200 border border-gray-300">
                                    Please let
                                    <span className="text-gray-900 font-bold px-1 inline">{job?.user.username}</span>
                                    know you found this on
                                    <span className="text-gray-900 font-bold ml-1 inline">wefayo</span>,
                                    with your support we can keep growing the community.
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            }
        </MainLayout>
    );
};

export default JobDetails;